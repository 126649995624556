.mini_accordion {
  color: var(--gray-dark);
  .MuiAccordion-root {
    border: none;
    margin: 0;
    .MuiAccordionSummary-root {
      padding: 0.4rem 1rem 0.4rem 2rem;
      min-height: 0;
      display: flex;
      flex-direction: row-reverse;

      .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
        transform: rotate(90deg);
      }
      p {
        display: flex;
        align-items: center;
        padding-left: 0.3rem;
        gap: 0.3rem;
        font-weight: 600;
        line-height: 19px;
        font-size: 0.9rem;
        color: var(--gray-dark);
      }
      .MuiAccordionSummary-content {
        margin: 0;
      }
      &:hover {
        background: var(--gray-light);
      }
    }
    .active {
      background: var(--gray-light);
    }
    .MuiAccordionDetails-root {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      span {
        // align-items: center;
        // padding: 0.3rem 0.3rem;
        color: var(--gray-dark);
        font-size: 0.9rem;
        gap: 0.3rem;
        &:hover {
          background: var(--gray-dark);
        }
      }
    }
  }
}
