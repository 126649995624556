:root {
  --blue: #4e73df;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #e74a3b;
  --orange: #fd7e14;
  --yellow: #f6c23e;
  --green: #1cc88a;
  --teal: #20c9a6;
  --cyan: #36b9cc;
  --white: #fff;
  --white-30: rgba(255, 255, 255, 0.3);
  --gray: #858796;
  --gray-light: #d1d3e2;
  --gray-dark: #5a5c69;
  --primary: #4e73df;
  --secondary: #858796;
  --success: #1cc88a;
  --info: #36b9cc;
  --warning: #f6c23e;
  --danger: #e74a3b;
  --light: #f8f9fc;
  --dark: #5a5c69;
}

/* background-colors */

.bg-none {
  background: none;
}

.bg-blue {
  background: var(--blue);
}

.bg-indigo {
  background: var(--indigo);
}

.bg-purple {
  background: var(--purple);
}

.bg-pink {
  background: var(--pink);
}

.bg-red {
  background: var(--red);
}

.bg-orange {
  background: var(--orange);
}

.bg-yellow {
  background: var(--yellow);
}

.bg-green {
  background: var(--green);
}

.bg-teal {
  background: var(--teal);
}

.bg-cyan {
  background: var(--cyan);
}

.bg-white {
  background: var(--white);
}

.bg-white-30 {
  background: var(--white-30);
}

.bg-gray {
  background: var(--gray);
}

.bg-gray-light {
  background: var(--gray-light);
}

.bg-gray-dark {
  background: var(--gray-dark);
}

.bg-primary {
  background: var(--primary);
}

.bg-secondary {
  background: var(--secondary);
}

.bg-success {
  background: var(--success);
}

.bg-info {
  background: var(--info);
}

.bg-warning {
  background: var(--warning);
}

.bg-danger {
  background: var(--danger);
}

.bg-light {
  background: var(--light);
}

.bg-dark {
  background: var(--dark);
}

/* background-colors *

/* text-colors */

.color-blue {
  color: var(--blue);
}

.color-indigo {
  color: var(--indigo);
}

.color-purple {
  color: var(--purple);
}

.color-pink {
  color: var(--pink);
}

.color-red {
  color: var(--red);
}

.color-orange {
  color: var(--orange);
}

.color-yellow {
  color: var(--yellow);
}

.color-green {
  color: var(--green);
}

.color-teal {
  color: var(--teal);
}

.color-cyan {
  color: var(--cyan);
}

.color-white {
  color: var(--white);
}

.color-white-30 {
  color: var(--white-30);
}

.color-gray {
  color: var(--gray);
}

.color-gray-light {
  color: var(--gray-light);
}

.color-gray-dark {
  color: var(--gray-dark);
}

.color-primary {
  color: var(--primary);
}

.color-secondary {
  color: var(--secondary);
}

.color-success {
  color: var(--success);
}

.color-info {
  color: var(--info);
}

.color-warning {
  color: var(--warning);
}

.color-danger {
  color: var(--danger);
}

.color-light {
  color: var(--light);
}

.color-dark {
  color: var(--dark);
}

/* text-colors */

/* -------------------------------------- */

/* widths */
.w-2r {
  width: 2rem;
}

.w-4r {
  width: 4rem;
}

.w-8r {
  width: 8rem;
}

.w-10r {
  width: 10rem;
}

.w-25r {
  width: 25rem;
}

.w-30r {
  width: 30rem;
}

.w-10 {
  width: 10%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-50 {
  width: 50%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-90 {
  width: 90%;
}

.w-full {
  width: 100%;
}

.w-min-full {
  min-width: 100%;
}

.w-max-85vw {
  max-width: 85vw;
}

.w-max-full {
  max-width: 100%;
}

.w-calc-4r {
  width: calc(100% - 4rem);
}

.w-calc-10r {
  width: calc(100% - 10rem);
}

.w-calc-100-11 {
  width: calc(100% / 11);
}

/* widths */

/* -------------------------------------- */

/* heights */

.h-1r {
  height: 1rem;
}

.h-1_5r {
  height: 1.5rem;
}

.h-2r {
  height: 2rem;
}
.h-3_5r {
  height: 3.5rem;
}

.h-30r {
  height: 30rem;
}

.h-35r {
  height: 35rem;
}

.h-50 {
  height: 50%;
}

.h-full {
  height: 100%;
}

.h-70vh {
  height: 70vh;
}

.h-100vh {
  height: 100vh;
}
.h-max-58vh {
  max-height: 58vh;
}
.h-max-70vh {
  max-height: 70vh;
}
.h-max-80vh {
  max-height: 80vh;
}
.h-max-100vh {
  max-height: 100vh;
}

/* heights */

/* -------------------------------------- */

/* displays */

.hidden {
  display: none;
}

.flex {
  display: flex;
}

.block {
  display: block;
}

.grid {
  display: grid;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.grid-temp-col-1-100 {
  grid-template-columns: repeat(1, 100%);
}

.grid-temp-col-1-49 {
  grid-template-columns: repeat(2, 49.2%);
}

/* displays */

/* -------------------------------------- */

/* font-sizes */

/* sm */
.fs-sm {
  font-size: 0.5rem;
}

/* xs */
.fs-xs {
  font-size: 0.7rem;
}

/* md */
.fs-md {
  font-size: 0.9rem;
}

/* xm */
.fs-xm {
  font-size: 1rem;
}

/* lg */
.fs-lg {
  font-size: 1.3rem;
}

/* xl */
.fs-xl {
  font-size: 1.5rem;
}

/* 2xl */
.fs-2xl {
  font-size: 2rem;
}

/* 3xl */
.fs-3xl {
  font-size: 3rem;
}

.text-decoration-none {
  text-decoration: none;
}

.fw-100 {
  font-weight: 100;
}

.fw-50 {
  font-weight: 50;
}

.fw-500 {
  font-weight: 500;
}
.fw-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.text-nowrap {
  white-space: nowrap;
}

/* font-sizes */

/* -------------------------------------- */

/* margins */
.m-auto {
  margin: auto;
}

.m-2 {
  margin: 2rem;
}

.m-1 {
  margin: 1rem;
}

.mx-auto {
  margin: 0 auto;
}

.m-0_2 {
  margin: 0.2rem;
}

.mt-0_2 {
  margin-top: 0.2rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-1_5 {
  margin-top: 1.5rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-2_5 {
  margin-top: 2.5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.ml-1 {
  margin-left: 1rem;
}

.mr-0_5 {
  margin-right: 0.5rem;
}

.mx-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-2 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-0_5 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mx-0_5 {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.my-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-1_5 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* margins */

/* -------------------------------------- */

/* paddings */

.p-0_5 {
  padding: 0.5rem;
}

.p-1 {
  padding: 1rem;
}

.p-1_5 {
  padding: 1.5rem;
}

.p-2 {
  padding: 2rem;
}

.p-0_6 {
  padding: 0.6rem;
}

.px-0_5 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-4 {
  padding: 4rem;
}

.px-0_1 {
  padding-left: 0.1px;
  padding-right: 0.1px;
}

.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0_5 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-0_2 {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.py-0_7 {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-1_5 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pb-8px {
  padding-bottom: 8px;
}

.py-1_5 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pl-0_3 {
  padding-left: 0.3rem;
}

.pr-0_5 {
  padding-right: 0.5rem;
}
.pt-1 {
  padding-top: 1rem;
}

/* paddings */

/* -------------------------------------- */

/* gaps */

.gap-0_5 {
  gap: 0.5rem;
}

.gap-1 {
  gap: 1rem;
}

.gap-col-1 {
  column-gap: 1rem;
}

.gap-row-2_5 {
  row-gap: 2.5rem;
}

/* gaps */

/* -------------------------------------- */

/* cursors */

.cursor-pointer {
  cursor: pointer;
}

/* cursors */

/* -------------------------------------- */

/* borders */

.outline-none {
  outline: none;
}
.outline-1px {
  outline: 1px solid;
}
.outline-gray-light {
  outline-color: var(--gray-light);
}

.border-box {
  box-sizing: border-box;
}

.border-none {
  border: none;
}

.border-1px {
  border: 1px solid;
}

.border-2px {
  border: 2px solid;
}

.border-left-4px {
  border-left: 4px solid;
}

.border-top-1px {
  border-top: 1px solid;
}

.border-bottom-1px {
  border-bottom: 1px solid;
}

.border-circle {
  border-radius: 5rem;
}

.border-radius-0 {
  border-radius: 0;
}

.border-radius-0 {
  border-radius: 0;
}

.border-radius-0_3r {
  border-radius: 0.3rem;
}

.border-radius-0_5r {
  border-radius: 0.5rem;
}

.border-left-1px {
  border-left: 1px solid;
}

.border-gray {
  border-color: var(--gray);
}

.border-gray-light {
  border-color: var(--gray-light);
}

.border-light {
  border-color: var(--light);
}

.border-red {
  border-color: var(--red);
}

.border-collapse {
  border-collapse: collapse;
}

.border-blue {
  border-color: var(--blue);
}

.border-green {
  border-color: var(--green);
}

.border-cyan {
  border-color: var(--cyan);
}

.border-orange {
  border-color: var(--orange);
}

/* borders */

/* -------------------------------------- */

/* transitions */

.transition-0_3s {
  transition: 0.3s;
}

.transition-2s {
  transition: 2s;
}

/* transitions */

/* -------------------------------------- */

/* shadows */

.box-shadow-0-0-1-0_5 {
  box-shadow: 0 0rem 1rem 0.05rem var(--gray-light);
}

.box-shadow {
  box-shadow: 0 0rem 1rem 0.5rem var(--gray-light);
}

.box-shadow-top {
  box-shadow: 0 1rem 2rem 0.1rem var(--gray-light);
}

.box-shadow-bottom {
  box-shadow: 0 1rem 2rem 0.1rem var(--gray-light);
}

/* shadows */

/* -------------------------------------- */

/* placeholders */

.placeholder-gray-light::placeholder {
  color: var(--gray-light);
}

/* placeholders */

/* -------------------------------------- */

/* positions */

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: sticky;
}

.overflowX-scroll {
  overflow-x: scroll;
}

.overflowY-scroll {
  overflow-y: scroll;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-hidden {
  overflow: hidden;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.right-0_5r {
  right: 0.5rem;
}

.bottom-0 {
  bottom: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

/* positions */

/* -------------------------------------- */

/* hovers */

.hover-color-white:hover {
  color: var(--white);
}

.hover-color-blue:hover {
  color: var(--blue);
}

.hover-bg-secondary:hover {
  background: var(--secondary);
}

.hover-bg-white:hover {
  background: var(--white);
}

.hover-bg-gray-light:hover {
  background: var(--gray-light);
}

.hover-bg-light:hover {
  background: var(--light);
}

/* hovers */

/* -------------------------------------- */

/* actives */

.active-outline-3px:active {
  outline: 3px solid;
}

/* actives */

/* -------------------------------------- */

@media screen and (max-width: 1920px) {
  /* 2xl */
}

@media screen and (max-width: 1440px) {
  /* xl */
}

@media screen and (max-width: 1024px) {
  /* lg */
  .lg\:w-15r {
    width: 25rem;
  }
}

@media screen and (max-width: 768px) {
  /* md */
  .md\:w-5r {
    width: 5rem;
  }

  .md\:w-90 {
    width: 90%;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:w-40r {
    width: 40rem;
  }

  .md\:h-3_5r {
    height: 3.5rem;
  }

  .md\:h-max-30r {
    max-height: 20rem;
  }

  .md\:screen {
    display: initial;
  }

  .md\:hidden {
    display: none;
  }

  .md\:block {
    display: block;
  }

  .md\:d-inline-block {
    display: inline-block;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-between {
    justify-content: center;
  }

  .md\:mb-1 {
    margin-bottom: 1rem;
  }

  .md\:grid-temp-col-1-100 {
    grid-template-columns: repeat(1, 100%);
  }

  .md\:p-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media screen and (max-width: 425px) {
  /* sm */
  .sm\:flex {
    display: flex;
  }
  .sm\:flex-col {
    flex-direction: column;
  }
  .sm\:w-full {
    width: 100%;
  }
  .sm\:gap-4 {
    gap: 1rem;
  }
  .sm\:hidden {
    display: none;
  }
  .sm\:screen {
    display: initial;
  }
}
