@import './assets/styles/style.css';
@import './assets/styles/hamburgers.css';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
}

button:hover {
  cursor: pointer;
  filter: brightness(90%);
  transition: 0.3s;
}
